

class qDeleteAtUtils {
  constructor() {
  }

  delete_update_at(dataTo) {
  
    return dataTo.filter((__dataTo) => {
        return __dataTo.delete_update_at === 0;
      });
  }
}

export default new qDeleteAtUtils();

