<script>
import { reactive, ref } from "@vue/composition-api";
import { methods } from "vue-echarts";
import axios from "axios";
import URL from "@/views/pages/request";
import { toast_sucess } from '@/utils/qToast';
import moment from 'moment';

export default {
  props: ["files", 'state'],
  data() {
    return {
      describle: "",
      filesAvatar: "",
      err: null,
      loadingForAddfile: false,
      errorInputForm: {
        path: "",
        message: "",
      },
    };
  },
  methods: {
    /***
    SEND ATTACHEMENTS FILES
    @Method > Post
    @variable > [Files]
    @return > Array<Object>
  */
    reqFileToInvoiceDetails(event) {
      this.filesAvatar = event.target.files[0];
    },

     sendFileToInvoiceDetails() {
      const facture = JSON.parse(localStorage.getItem("facture"));
      const fileElements = this.filesAvatar;
      console.log(this.$props);

      if (this.filesAvatar.length === 0) {
        this.errorInputForm.path = "file";
        this.errorInputForm.message = "veillez svp ajoute un files a ce champs";
        return { error: true };
      } else if (this.describle === "") {
        this.errorInputForm.path = "describle";
        this.errorInputForm.message = "veillez svp ajoute un files a ce champs";
        return { error: true };
      } else if (this.describle.length < 3) {
        this.errorInputForm.path = "describle";
        this.errorInputForm.message = "Ce champs requis 3 charactere";
        return { error: true };
      }else if (this.filesAvatar.size > 5000000) {
        this.errorInputForm.path = "file";
        this.errorInputForm.message = "La taille du fichier est superieur a 5Mo";
        return { error: true };
      } else {
        this.errorInputForm.path = "none";
        this.errorInputForm.message = "";
        this.loadingForAddfile = true;
        //   const files_input = document.querySelector('#file-sendFilesBillPayments__id')
        const formData = new FormData();
        formData.append("id", facture.id);
        formData.append("message", this.describle);
        formData.append("avatar", fileElements);
         axios
          .post(URL.INVOICE_SEND_FILES, formData)
          .then(({ data }) => {
            this.$props.state.count += 1;
            this.$props.state.success = true;
            this.$props.state.empty = false;
            this.$props.state.warring = false;
            const file = data.facture_stored[0]
            this.files.unshift({
              id: file.id,
              types: file.media[
                  file.media.length - 1
               ].mime_type.split('/')[0],
              descriptions: file.media[
                  file.media.length - 1
               ].manipulations.commentaire.message,
              original_url: file.media[
                  file.media.length - 1
               ].original_url,
              created_at: moment(file.created_at)
                .format('DD-MM-YYYY à HH:mm')
                .toString(),
            });
            this.$bvModal.hide("modal-sendFilesBillPayments");
            toast_sucess(this, "success", 'top-right', 'Ficher ajouter avec succés !')
            this.loadingForAddfile = false;
          })
          
      }
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      centered
      id="modal-sendFilesBillPayments"
      scrollable
      @ok="sendFileToInvoiceDetails"
      title="Ajouter des files assoccier a la facture"
    >
      <b-card-text>
        <!-- first name -->
        <b-col cols="12">
          <form ref="form" @submit.stop.prevent>
            <!-- Files Input -->
            <b-form-group label="" label-cols-sm="0">
              <b-form-file
                @change="reqFileToInvoiceDetails"
                id="file-sendFilesBillPayments__id"
              ></b-form-file>
              <small class="text-danger" v-if="errorInputForm.path === 'file'">
                {{ errorInputForm.message }}
              </small>
            </b-form-group>

            <!-- Describle Input -->
            <b-form-group label="" label-for="fh-describle">
              <b-form-input
                id="fh-describle"
                type="text"
                v-model="describle"
                placeholder="Entre un descriptif du file"
              />
              <small
                class="text-danger"
                v-if="errorInputForm.path === 'describle'"
              >
                {{ errorInputForm.message }}
              </small>
            </b-form-group>
          </form>
        </b-col>
      </b-card-text>

      <template #modal-footer>
        <b-button
          :disabled="loadingForAddfile === true ? true : false"
          variant="primary"
          @click="sendFileToInvoiceDetails"
        >
          <span v-if="loadingForAddfile === false">Ajouter</span>
          <b-spinner
            v-if="loadingForAddfile === true"
            label="Spinning"
          ></b-spinner>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style scoped></style>
