import { render, staticRenderFns } from "./qLoader.vue?vue&type=template&id=24717afe&scoped=true&"
import script from "./qLoader.vue?vue&type=script&lang=js&"
export * from "./qLoader.vue?vue&type=script&lang=js&"
import style0 from "./qLoader.vue?vue&type=style&index=0&id=24717afe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24717afe",
  null
  
)

export default component.exports